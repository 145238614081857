





































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { State, Getter, Action } from 'vuex-class';
import dayjs from 'dayjs';
import _get from 'lodash/get';
import { saveAs } from 'file-saver';
import Container from '@/components/Container.vue';
import Card from '@/components/Card.vue';
import { journalApi } from '@/api/journal.api';
import { ToastProgrammatic as Toast } from 'buefy';
import PaginatedJournalList from './components/PaginatedJournalList.vue';
import { GetJournalsRequestPayload, Journal } from '@/store/modules/journal';
import { PaginatedResponse } from '@/store/types/general.types';
import { RootState } from '@/store/store';

@Component({
  components: {
    Container,
    Card,
    PaginatedJournalList
  }
})
export default class Journals extends Vue {
  public selectedDate = new Date();
  public perPage = 50;
  public page = 1;

  get dayjs() {
    return dayjs;
  }

  @State((state: RootState) => state.journal.journals)
  public journals!: PaginatedResponse<Journal>;

  @Action('journal/getJournals')
  public getJournals!: (payload: GetJournalsRequestPayload) => void;

  public mounted() {
    this.handlePaginator();
  }

  public handleDownload(url: string) {
    return saveAs(url);
  }

  public handlePaginator() {
    this.getJournals({
      perPage: this.perPage,
      page: this.page,
      selectedDate: dayjs(this.selectedDate).format('YYYY-MM-DD')
    });
  }

  @Watch('selectedDate')
  public onSelectMonth(value: Date) {
    if (value) {
      this.page = 1;
      this.handlePaginator();
    }
  }
}
