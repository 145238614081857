












import { Component, Prop, Vue } from 'vue-property-decorator';
import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import JournalList from './JournalList.vue';
import { mixins } from 'vue-class-component';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import { Journal } from '@/store/modules/journal';

@Component({
  components: { BasePaginatorHoc }
})
export default class PaginatedJournalList extends mixins(PaginationMixin) {
  @Prop() public items!: Journal[];
  @Prop() public totalCount!: number;

  get JournalList() {
    return JournalList;
  }
}
