












































































import { Component, Prop } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';
import dayjs from 'dayjs';
import { mixins } from 'vue-class-component';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import { Journal } from '@/store/modules/journal';
import BaseTable from '@/components/BaseTable.vue';
import BaseLoading from '@/components/base/BaseLoading.vue';
import BasePagination from '@/components/base/BasePagination.vue';
import { cloneDeep as _cloneDeep } from 'lodash';

@Component({
  components: {
    BaseTable,
    BasePagination,
    BaseLoading
  }
})
export default class JournalList extends mixins(PaginationMixin) {
  @Prop() public items!: Journal[];
  @Prop(Number) public currentPage!: number;
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;

  get journalItems() {
    return this.items || [];
  }

  get dayjs() {
    return dayjs;
  }

  public handleStatus(status: string) {
    const transformText = status.replace('_', ' ').toLowerCase();
    return transformText.charAt(0).toUpperCase() + transformText.slice(1);
  }
  public getUserName(item: Journal) {
    return item.payment.user.userProfile
      ? item.payment.user.userProfile.firstName +
          ' ' +
          item.payment.user.userProfile.lastName
      : item.payment.user.email;
  }

  public redirectToUserSubscriptionPage(item: Journal) {
    const query = {
      tab: 'Subscriptions',
      productId: '',
      email: item.payment.user.email,
      applicationId: '0'
    };
    if (item.payment.order.orderItems[0]) {
      query.productId = item.payment.order.orderItems[0].id.toString();
      query.applicationId = item.payment.order.orderItems[0].applicationId.toString();
    }

    this.$router.replace({ path: '/admin/user-management', query });
  }
}
